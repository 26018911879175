import { useNavigate } from "react-router-dom";
import { Job } from "../../interfaces/interfaces";
import { convertUnixToReadable } from "../../utils/formatters";

interface Props {
  job: Job;
}

const JobCard = ({ job }: Props) => {
  const navigate = useNavigate();

  return (
    <tr
      className="job-box"
      onClick={() => navigate(`/job-details/${job.uuid}`)}
    >
      <td>{job.uuid}</td>
      <td>
        <b>{job.status}</b>
      </td>
      <td>{convertUnixToReadable(job.creationTime, "DD-MM-YYYY")}</td>
    </tr>
  );
};
export default JobCard;
