import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import JobCard from "../../components/JobCard/JobCard";
import { Input } from "../../components/styled/styled-components";
import ArrowDown from "../../icons/ArrowDown";
import ArrowUp from "../../icons/ArrowUp";
import ArrowUpDown from "../../icons/ArrowUpDown";
import { Job } from "../../interfaces/interfaces";
import { mockGetJobList } from "../../mock-data/jobs";
import { sortJobs } from "../../utils/sorter";

interface Sort {
  type: "creationTime" | "status" | "uuid";
  direction: "asc" | "desc";
}

const Jobs = () => {
  const [jobList, setJobList] = useState<Job[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [sort, setSort] = useState<Sort>({
    type: "uuid",
    direction: "asc",
  });

  useEffect(() => {
    const jobs = mockGetJobList();

    setJobList(jobs);
  }, []);

  const filteredJobList = jobList.filter(
    (job) =>
      job.uuid.toLowerCase().includes(searchFilter.toLowerCase()) ||
      job.clientInfo.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
      job.clientInfo.email.toLowerCase().includes(searchFilter.toLowerCase())
  );

  return (
    <div className="jobs-page">
      <div className="top-bar">
        <Input
          onChange={(event: any) => setSearchFilter(event.target.value)}
          placeholder="Search by job ID or client name/email"
        />
        <Link to="/jobs/create">Create job</Link>
      </div>
      {filteredJobList.length > 0 ? (
        <table>
          <thead>
            <tr>
              {/* TODO - add these table headings to an object/array, so we can map it instead of repeating code */}
              <th
                onClick={() =>
                  setSort({
                    type: "uuid",
                    direction: sort.direction === "asc" ? "desc" : "asc",
                  })
                }
              >
                ID
                {sort.type === "uuid" ? (
                  sort.direction === "asc" ? (
                    <ArrowDown />
                  ) : (
                    <ArrowUp />
                  )
                ) : (
                  <ArrowUpDown />
                )}
              </th>
              <th
                onClick={() =>
                  setSort({
                    type: "status",
                    direction: sort.direction === "asc" ? "desc" : "asc",
                  })
                }
              >
                Status
                {sort.type === "status" ? (
                  sort.direction === "asc" ? (
                    <ArrowDown />
                  ) : (
                    <ArrowUp />
                  )
                ) : (
                  <ArrowUpDown />
                )}
              </th>
              <th
                onClick={() =>
                  setSort({
                    type: "creationTime",
                    direction: sort.direction === "asc" ? "desc" : "asc",
                  })
                }
              >
                Date created
                {sort.type === "creationTime" ? (
                  sort.direction === "asc" ? (
                    <ArrowDown />
                  ) : (
                    <ArrowUp />
                  )
                ) : (
                  <ArrowUpDown />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortJobs(filteredJobList, sort).map((job, index) => (
              <JobCard job={job} key={index} />
            ))}
          </tbody>
        </table>
      ) : (
        <h1>No jobs matched the search criteria</h1>
      )}
    </div>
  );
};

export default Jobs;
