import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { populateJobListLocalStorage } from "./mock-data/localStorage";
import CreateJob from "./pages/create-job";
import Home from "./pages/home";
import JobDetails from "./pages/job-details";
import Jobs from "./pages/jobs";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.scss";

// Initial data store population - only needed because we don't have an actual back-end
// In this case, localStorage is our "back-end" and we need to "fetch" from it.
populateJobListLocalStorage();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/jobs" element={<Jobs />} />
      <Route path="/jobs/create" element={<CreateJob />} />
      <Route path="/job-details/:jobId" element={<JobDetails />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
