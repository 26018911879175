import { convertUnixToReadable } from "../../../utils/formatters";

interface Props {
  creationTime: number;
  uuid: string;
}
const JobDetailsSection = ({ uuid, creationTime }: Props) => (
  <section className="job-details">
    <h1>Job Details</h1>
    <p>
      <b>Job ID: </b>
      {uuid}
    </p>
    <p>
      <b>Created at: </b>
      {convertUnixToReadable(creationTime, "DD-MM-YYYY hh:mm:ssA")}
    </p>
  </section>
);

export default JobDetailsSection;
