import moment from "moment";

export const convertUnixToReadable = (
  unixTimestamp: number,
  format: string
) => {
  const m = moment.unix(unixTimestamp);
  const formattedDate = m.format(format);
  return formattedDate;
};
