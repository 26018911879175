import cx from "classnames";
import { useState } from "react";
import IconWrapper from "../../../components/IconWrapper/IconWrapper";
import { Button } from "../../../components/styled/styled-components";
import Edit from "../../../icons/Edit";
import { Status } from "../../../interfaces/types";

interface Props {
  status: Status;
  updateJobStatus: (status: string) => void;
}

const JobStatusBar = ({ status, updateJobStatus }: Props) => {
  const [mode, setMode] = useState<"edit" | "view">("view");
  const [statusField, setStatusField] = useState<Status>("scheduled");

  const saveStatus = () => {
    updateJobStatus(statusField);
    setMode("view");
  };

  return (
    <div className="alert-bar">
      <div>
        Invoice Status:{" "}
        <b
          className={cx({
            scheduled: status === "scheduled",
            active: status === "active",
            invoicing: status === "invoicing",
            "to-priced": status === "to priced",
            completed: status === "completed",
          })}
        >
          {mode === "view" ? (
            status
          ) : (
            <select
              defaultValue="scheduled"
              onChange={(event) => setStatusField(event.target.value as Status)}
            >
              {[
                "scheduled",
                "active",
                "invoicing",
                "to priced",
                "completed",
              ].map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}
        </b>
      </div>
      {mode === "view" ? (
        <IconWrapper onClick={() => setMode("edit")}>
          <Edit />
        </IconWrapper>
      ) : (
        <div className="button-container">
          <Button onClick={() => saveStatus()}>Save</Button>
          <Button onClick={() => setMode("view")}>Cancel</Button>
        </div>
      )}
    </div>
  );
};

export default JobStatusBar;
