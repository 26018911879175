import { ClientInfo } from "../../../interfaces/interfaces";

interface Props {
  clientInfo: ClientInfo;
}

const ClientSection = ({ clientInfo }: Props) => {
  return (
    <section className="client-details">
      <h1>Client Details</h1>
      <p>
        <b>Name: </b>
        {clientInfo.name}
      </p>
      <p>
        <b>Email: </b>
        {clientInfo.email}
      </p>
      <p>
        <b>Phone Number: </b>
        {clientInfo.phone}
      </p>
    </section>
  );
};

export default ClientSection;
