import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Job } from "../../interfaces/interfaces";
import { mockGetJobDetails } from "../../mock-data/jobs";
import { updateJobDetailFieldLocalStorage } from "../../mock-data/localStorage";
import ClientSection from "./ClientSection/ClientSection";
import JobDetailsSection from "./JobDetailsSection/JobDetailsSection";
import JobStatusBar from "./JobStatusBar/JobStatusBar";
import NoteSection from "./NoteSection/NoteSection";

const JobDetails = () => {
  const params = useParams<{ jobId: string }>();
  const [fetchedDetails, setFetchedDetails] = useState<Job | null>();

  useEffect(() => {
    if (params.jobId) {
      const jobDetails = mockGetJobDetails(params.jobId);
      setFetchedDetails(jobDetails);
    }
  }, [params]);

  const saveNotes = (notes: string[]) => {
    if (params.jobId) {
      updateJobDetailFieldLocalStorage(notes, params.jobId, "notes");

      //Reload page to get new notes
      document.location.reload();
    }
  };

  const updateJobStatus = (status: string) => {
    if (params.jobId) {
      updateJobDetailFieldLocalStorage(status, params.jobId, "status");

      //Reload page to get new notes
      document.location.reload();
    }
  };

  return (
    <div className="job-details-page">
      <Link to="/jobs" style={{ display: "block", marginBottom: "1em" }}>
        Back to job list
      </Link>
      {fetchedDetails ? (
        <>
          <JobStatusBar
            updateJobStatus={updateJobStatus}
            status={fetchedDetails.status}
          />
          <JobDetailsSection
            uuid={fetchedDetails.uuid}
            creationTime={fetchedDetails.creationTime}
          />
          <ClientSection clientInfo={fetchedDetails.clientInfo} />
          <NoteSection saveNotes={saveNotes} notes={fetchedDetails.notes} />
        </>
      ) : (
        <h1>Loading...</h1>
      )}
    </div>
  );
};

export default JobDetails;
