import { Job } from "./../interfaces/interfaces";

export const JOB_LIST: Job[] = [
  {
    uuid: "9ad7c10e-d059-11ec-9d64-0242ac120002",
    status: "scheduled",
    creationTime: 1162964014,
    clientInfo: {
      name: "Steve Jobs",
      phone: "0800 83 83 83",
      email: "stevejobs@gmail.com",
    },
    notes: ["This is a note", "This is another note"],
  },
  {
    uuid: "ecdc2bd4-d059-11ec-9d64-0242ac120002",
    status: "active",
    creationTime: 1354675468,
    clientInfo: {
      name: "Bill Gates",
      phone: "0800 82 82 82",
      email: "billgates@gmail.com",
    },
    notes: ["This is a note", "This is another note"],
  },
  {
    uuid: "f04a1c86-d059-11ec-9d64-0242ac120002",
    status: "invoicing",
    creationTime: 1528948117,
    clientInfo: {
      name: "Mark Zuckerberg",
      phone: "0800 81 81 81",
      email: "markzuckerberg@gmail.com",
    },
    notes: ["This is a note", "This is another note"],
  },
  {
    uuid: "fba63714-d0bc-11ec-9d64-0242ac120002",
    status: "to priced",
    creationTime: 1608585604,
    clientInfo: {
      name: "Jeff Bezos",
      phone: "0800 80 80 80",
      email: "jeffbezos@amazon.com",
    },
    notes: ["This is a note", "This is another note"],
  },
  {
    uuid: "ff5e7d94-d0bc-11ec-9d64-0242ac120002",
    status: "completed",
    creationTime: 1353154991,
    clientInfo: {
      name: "Ted Sarandos",
      phone: "0800 89 89 89",
      email: "tedsarandos@netflix.com",
    },
    notes: ["This is a note", "This is another note"],
  },
  {
    uuid: "4e4e6cec-d0cd-11ec-9d64-0242ac120002",
    status: "active",
    creationTime: 1535680901,
    clientInfo: {
      name: "Sundar Pichai",
      phone: "0800 88 88 88",
      email: "sundarpichai@google.com",
    },
    notes: ["This is a note", "This is another note"],
  },
];

export const mockGetJobList = (): Job[] => {
  return JSON.parse(localStorage.getItem("jobList") ?? "") as Job[];
};

export const mockGetJobDetails = (jobId: string) => {
  const jobList = JSON.parse(localStorage.getItem("jobList") ?? "") as Job[];

  return jobList.filter((job) => job.uuid === jobId)[0];
};
