import { useEffect, useState } from "react";
import IconWrapper from "../../../components/IconWrapper/IconWrapper";
import { Button, Input } from "../../../components/styled/styled-components";
import Cross from "../../../icons/Cross";

interface Props {
  notes: string[];
  saveNotes: (notes: string[]) => void;
}

const NoteSection = ({ notes, saveNotes }: Props) => {
  const [mode, setMode] = useState<"edit" | "add" | "view">("view");
  const [editFields, setEditFields] = useState<string[]>(notes);
  const [addNoteField, setAddNoteField] = useState<string>("");

  useEffect(() => {
    setEditFields(notes);
  }, [notes]);

  const resetFields = () => {
    setEditFields(notes);
    setAddNoteField("");
  };

  const updateField = (value: string, index: number) => {
    const fields = editFields.slice();
    fields[index] = value;
    setEditFields(fields);
  };

  const updateNotes = () => {
    if (mode === "edit") {
      saveNotes(editFields);
    } else if (mode === "add") {
      saveNotes([...notes, addNoteField]);
    }
    setMode("view");
  };

  const removeNote = (index: number) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this note? This action can not be undone"
    );
    if (shouldDelete) {
      const fields = editFields.slice();
      fields.splice(index, 1);
      saveNotes(fields);
    }
  };

  return (
    <section className="notes">
      <p>
        <b>Notes:</b>
      </p>
      {notes.map((note, index) => {
        if (mode === "view" || mode === "add") {
          return <p key={index}>{note}</p>;
        } else {
          return (
            <div key={index} className="field-wrapper">
              <Input
                onChange={(event) => updateField(event.target.value, index)}
                value={editFields[index]}
              />
              <IconWrapper onClick={() => removeNote(index)}>
                <Cross />
              </IconWrapper>
            </div>
          );
        }
      })}
      {mode === "add" && (
        <Input
          value={addNoteField}
          onChange={(event) => setAddNoteField(event.target.value)}
        />
      )}
      <div className="button-container">
        {mode === "view" ? (
          <>
            <Button onClick={() => setMode("add")}>Add a new note</Button>
            <Button onClick={() => setMode("edit")}>Edit / Delete notes</Button>
          </>
        ) : (
          <>
            <Button onClick={() => updateNotes()}>Save</Button>
            <Button
              onClick={() => {
                setMode("view");
                resetFields();
              }}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    </section>
  );
};

export default NoteSection;
