import { Job } from "../interfaces/interfaces";
import { JOB_LIST } from "./jobs";

//Only populate if field is initially empty
export const populateJobListLocalStorage = () => {
  if (!localStorage.getItem("jobList")) {
    localStorage.setItem("jobList", JSON.stringify(JOB_LIST));
  }
};

export const updateJobDetailFieldLocalStorage = (
  data: any,
  jobId: string,
  field: "notes" | "status"
) => {
  // Ideally "notes" and the job list shouldnt be in one object, they should be separated since job list requires less data than job-detail
  // And when calling job-list in the real world from an actual API, there would be a lot less redundant data returned if notes was omitted from the response
  // But since this is just a test, I will put them both in the same object for now
  const jobListString = localStorage.getItem("jobList");
  if (jobListString) {
    const jobList = JSON.parse(jobListString) as Job[];
    for (const jobDetails of jobList) {
      if (jobDetails.uuid === jobId) {
        jobDetails[field] = data;
        break;
      }
    }

    localStorage.setItem("jobList", JSON.stringify(jobList));
  }
};

export const createJobLocalStorage = (job: Job): "success" | "fail" => {
  const jobListString = localStorage.getItem("jobList");
  if (jobListString) {
    const jobList = JSON.parse(jobListString) as Job[];

    //Check for conflicting uuid
    for (const jobDetails of jobList) {
      if (jobDetails.uuid === job.uuid) {
        return "fail";
      }
    }
    const newList = [...jobList, job];

    localStorage.setItem("jobList", JSON.stringify(newList));
    return "success";
  } else {
    localStorage.setItem("jobList", JSON.stringify([job]));
    return "success";
  }
};
