import styled from "styled-components";

export const Button = styled("button")`
  background-color: white;
  border-radius: 4px;
  border: 1px solid black;
  padding: 0.5em;
  cursor: pointer;
`;

export const Input = styled('input')`
  padding: 0.5em;
  outline: none;
  border-radius: 3px;
  border: 1px solid black;

  &:focus {
    border: 1px solid blue;
  }
`