import { Job, Sort } from "../interfaces/interfaces";

export const sortJobs = (jobList: Job[], sort: Sort) => {
  // Prevent mutation on original
  const jobs = jobList.slice();

  const direction = sort.direction === "asc" ? 1 : -1;
  const sortedJobs = jobs.sort((a, b) => {
    if (a[sort.type] > b[sort.type]) {
      return 1 * direction;
    } else {
      return -1 * direction;
    }
  });

  return sortedJobs;
};
