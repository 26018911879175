import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>Home</h1>
      <Link to="/jobs">Click here to see the job list</Link>
    </div>
  );
};

export default Home;
