import moment from "moment";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Button, Input } from "../../components/styled/styled-components";
import { Job } from "../../interfaces/interfaces";
import { createJobLocalStorage } from "../../mock-data/localStorage";

const INITIAL_FORM_STATE: Job = {
  uuid: "",
  status: "invoicing",
  creationTime: 0,
  clientInfo: {
    name: "",
    phone: "",
    email: "",
  },
  notes: [],
};
const CreateJob = () => {
  const [form, setForm] = useState<Job>(INITIAL_FORM_STATE);
  const navigate = useNavigate();

  const setField = (
    value: string,
    key: "uuid" | "status" | "creationTime" | "clientInfo" | "notes",
    nestedKey?: string
  ) => {
    if (nestedKey) {
      setForm({
        ...form,
        [key]: {
          ...(form[key] as any),
          [nestedKey]: value,
        },
      });
    } else {
      setForm({
        ...form,
        [key]: value,
      });
    }
  };

  const submitForm = () => {
    const { clientInfo } = form;
    const { name, phone, email } = clientInfo;

    if (!name || !phone || !email) {
      alert("Please fill in all fields");
      return;
    }

    //Although there is a chance the UUID's can clash, the chance is so minimal, we will skip the check for this project.
    createJobLocalStorage({
      ...form,
      uuid: uuidv4(),
      creationTime: moment().unix(),
    });
    navigate("/jobs");
  };

  return (
    <div className="create-job-page">
      <Link to="/jobs">Back to job list</Link>
      <form>
        <h1>Client details</h1>
        <Input
          placeholder="Name"
          onChange={(event) =>
            setField(event.target.value, "clientInfo", "name")
          }
        />
        <Input
          placeholder="Email"
          onChange={(event) =>
            setField(event.target.value, "clientInfo", "email")
          }
        />
        <Input
          placeholder="Phone"
          onChange={(event) =>
            setField(event.target.value, "clientInfo", "phone")
          }
        />
        <Button onClick={submitForm}>Create job</Button>
      </form>
    </div>
  );
};

export default CreateJob;
