interface Props {
  children: JSX.Element;
  onClick: () => void;
}

const IconWrapper = ({ children, onClick }: Props) => {
  return (
    <div className="icon-wrapper" onClick={onClick}>
      {children}
    </div>
  );
};

export default IconWrapper;
